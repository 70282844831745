import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, 
  InputGroupText, InputGroup, Col, Alert } from "reactstrap";
import Loader from './../components/Loader';
import { withRouter } from 'react-router-dom';

const Recovery = ({ history }) => {
    const auth = useSelector(state => state.firebase.auth);
    const firebase = useFirebase();
    const[ userEmail, setUserEmail ] = useState('');
    const[ error, setError ] = useState(false);
    const[ messageType, setMessageType ] = useState('');
    const[ message, setMessage ] = useState('');

    const resetPassword = (e) => {
        e.preventDefault()
        firebase.resetPassword(userEmail)
        .then(result => {
          setError(true);
          setMessageType('alert-success')
          setMessage('Check your email !')
          setUserEmail('');
        })
        .catch(err => {
            setError(true);
            setMessageType('alert-danger')
          setMessage('User not found, try again.')
            setUserEmail('');
        });
    }
    return ( 
        <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          {
             !isLoaded(auth)
             ? <Loader />
             : isEmpty(auth) ?
             <>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={e => resetPassword(e) }>
              { error &&
                <Alert className={`${messageType}`}>
                  {message}
                </Alert>
              }
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={e => setUserEmail(e.target.value)}
                    value={userEmail}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Recovery
                </Button>
                <Button className="my-4" color="danger" type="button" onClick={ e => history.goBack() }>
                  Cancelar
                </Button>
              </div>
            </Form>
          </CardBody> </> : null
          }
        </Card>
      </Col>
    </>
     );
}
 
export default withRouter(Recovery);