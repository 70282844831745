import React, { useState, useEffect } from "react";
import { Row, Col, Input, Container } from "reactstrap";
import { firestoreConnect, useFirestore } from "react-redux-firebase";
import { connect, useSelector } from "react-redux";
import { isLoaded } from 'react-redux-firebase';
import { compose } from "redux";
import Select from "react-select";
import QuestionList from "../questions/QuestionList";


const AssesQuestions = ({catQuestion, types, callback, defaultData }) => {
    const [typesQ, setTypesQ] = useState([{ value: "", label: "Selecciona un Tipo"}]);
    const [categoryQ, setCategoryQ] = useState([{ value: "", label: "Selecciona una Categoria"}]);
    const [questions, saveQuestions] = useState([]);
    const fireStore = useFirestore();
    const auth = useSelector(state => state.firebase.auth);  

    const [fDesc, setDesc] = useState('');
    const [fType, setType] = useState({});
    const [fCat, setCat] = useState({});

    useEffect(() => {
        if (isLoaded(catQuestion) && catQuestion !== null) {
          const newvalues = Object.values(catQuestion).map((x) => {
            return { value: x.value, label: x.label };
          });
          setCategoryQ(state => {
            return state.concat(newvalues)
          });
        }
    }, [catQuestion]);

    const selectedQuestion = (checked, id) => {
      saveQuestions(state => {
        return state.map(x => {
          if(x.id === id){
            return {...x, checked}
          }
          return x;
        })
      })
    };

    useEffect(() => {  
      
      const getQuestionFilter = () => {
        return new Promise((resolve, reject) => {
          let query = fireStore.collection('questions').where('active','==', true)
          .where('created_by','==', auth.uid );

          if(Object.keys(fType).length > 0 && fType.value !== ""){
            query = query.where('type', '==', fType.value)
          }
          
          if(Object.keys(fCat).length > 0 && fCat.value !== ""){
            query = query.where('category', 'array-contains', fCat.value)
          }
          
          query.get().then(result => {
            if(result.docs.length > 0){
              let tmp= [];
              for(const questionList of result.docs){
                tmp.push({...questionList.data(), id: questionList.id });
              }

              if(fDesc !== "" && fDesc.length > 0){
                tmp = tmp.filter(el => el.description.toLowerCase().indexOf(fDesc.toLowerCase()) > -1)
              }
              saveQuestions(tmp);
            }else {
              saveQuestions([]);
            }
          }).catch(err => reject(err));
        })
      }

      getQuestionFilter();
    }, [fType, fDesc, fCat, auth, fireStore])

    useEffect(() => {
        if (isLoaded(types) && types !== null) {
          const newvalues = Object.values(types).map((x) => {
            return { value: x.value, label: x.label };
          });
          setTypesQ(state => {
            return state.concat(newvalues);
          });
        }
      }, [types]);

    useEffect(() => {
      let payload = {
        questionsAsses: questions.filter(x => x.checked !== undefined && x.checked === true)
      }
      callback(payload);
    },[questions, callback])
    return ( 
        <Container fluid className="mt-2">
            <Row className="text-center">
                <Col>
                    <h3>Reactivos</h3>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={4}>
                  <Input type="text" placeholder="Descripcion..." onChange={e => setDesc(e.target.value)} />
                </Col>
                <Col xs={4}>
                <Select
                      name="typeQ"
                      onChange={(values) => setCat(values)}
                      options={categoryQ}
                      defaultValue={categoryQ[0]}
                    />
                </Col>
                <Col xs={4}>
                <Select
                      name="typeQ"
                      onChange={(values) => setType(values)}
                      options={typesQ}
                      defaultValue={typesQ[0]}
                    />
                </Col>
            </Row>
            <QuestionList questions={questions} isCheckRender={true} isAnswerSlice={true} onCheckedItem={selectedQuestion}/>
        </Container>
     );
};

const mapCollectionFirebase = firestoreConnect((props) => [
    { collection: "cat_question_types", orderBy: ['value', 'asc'] },
    { collection: "cat_questions", orderBy: ['label', 'asc'] },
  ]);
  
  const mapStateToProps = (state) => ({
    types: state.firestore.data.cat_question_types,
    catQuestion: state.firestore.data.cat_questions,
  });
 
  export default compose( mapCollectionFirebase, connect(mapStateToProps, null))(AssesQuestions);