import React, { useState, useEffect } from 'react';
import { Input, Row, Col } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import AnswerParagraph from './AnswerParagraph';

const Paragraph = ({ callback }) => {
    const [oracion, setOracion] = useState('');
    const [respuestas, setRespuestas] = useState([]);

    useEffect(() => {
        callback({ data : { oracion, respuestas} });
    },[oracion, respuestas, callback])

    const evalText = (e) => {
        const textValue = e.target.value;
        setOracion(textValue);
        let arrWord = textValue.split(' ');

        arrWord = arrWord.filter(i => i === '??');
        for(let i=0 ; i<arrWord.length; i++){
            arrWord[i] = { index: i, value: respuestas[i] && respuestas[i].value ? respuestas[i].value : '' , id: uuid()}
        }

        setRespuestas(arrWord);
    }

    const changeAnswer = (value, index) => {
        setRespuestas(prev => {
            prev[index] = {...prev[index], value };
            return prev;
        });
    }

    return (
        <>
            <Row>
                <Col xs="12">
                    <Input rows={4} type="textarea" placeholder="Escribe la oracion" onChange={evalText} value={oracion} />
                </Col>
            </Row>
            <Row>
                { respuestas.map(r => (
                    <AnswerParagraph key={r.id} value={r.value} index={r.index} changeAnswer={changeAnswer} />
                ))}
            </Row>
        </>
    );
}
 
export default Paragraph;