import { SIGNUP_SUCCESS, SIGNOUT_SUCCESS, SIGNOUT_ERROR } from './../types';

export const logoutAction = (firebase) => {
    return async dispatch => {
        try{
            await firebase.auth().signOut();
            await firebase.logout();
            dispatch({ type: SIGNOUT_SUCCESS })
        }catch(e){
            console.log(e);
            dispatch({ type: SIGNOUT_ERROR })
        }
    }
}

export const loadDataUserAction = (data) => (
    dispatch => {
        dispatch({ type: SIGNUP_SUCCESS, payload: data })
    }
)