import React from 'react'
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const AnswerParagraph = ({ value, index, changeAnswer }) => {
    return (
        <Col xs={4} className="mt-3">
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>{`R${index + 1}`}</InputGroupText>
                </InputGroupAddon>
                <Input type="text" defaultValue={value} onChange={e => changeAnswer(e.target.value, index)}/>
            </InputGroup>
        </Col>
     );
}
 
export default AnswerParagraph;