import React, { Fragment } from 'react'
import { Row } from "reactstrap";
import shortId from 'shortid'

const ColumnsRelation = ({answer, isAnswerSlice}) => {
    
    let i = 0;
    
    //Build Table
    const  buildColumsRelation = (answer) =>{

        let table = (
            <table> 
                <tbody>
                    {answer.options.map(answ => (
                            buildRow(answ)
                    ))}
                </tbody> 
            </table>);
        return table;
    }

    //CHECK IF IT HAVE TO ADD OTHER ROW
    const buildRow = (answ) => {
        if(isAnswerSlice === true){
            if (i<2){
                i++;
                return addRow(answ);
            }
        }else
            return addRow(answ);
    }

    //ADD A ROW... A QUESTION WITH ITS ANSWER
    const addRow = (answ) => {
        let element = (
            <Fragment
                key={shortId.generate()}>
                <tr> 
                    <td> {answ.question} </td>
                </tr>
                <tr> 
                    <td><span className="answer">{answ.answer}</span></td>
                </tr>
            </Fragment>
        )

        return element;
    }

    return (  
        <Row>
            <div className="col mt-3">
                {buildColumsRelation(answer)}
            </div>
        </Row>
    );
}
 
export default ColumnsRelation;