import React, { useEffect, useState } from 'react';
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import JoditEditor from "jodit-react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { isLoaded } from 'react-redux-firebase';
import config from '../../config/config';

const QuestionHead = ({ category, setCategory, types, setTypeQuestion, description, setDescription }) => {
    const [typesQ, setTypesQ] = useState([]);
    const [categoryQ, setCategoryQ] = useState([]);
    
    useEffect(() => {
        if (isLoaded(category) && category !== null) {
          const newvalues = Object.values(category).map((x) => {
            return { value: x.value, label: x.label };
          });
          setCategoryQ(newvalues);
        }
    }, [category]);

    useEffect(() => {
        if (isLoaded(types) && types !== null) {
          const newvalues = Object.values(types).map((x) => {
            return { value: x.value, label: x.label };
          });
          setTypesQ(newvalues);
        }
      }, [types]);
    return ( 
        <>
        <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="">Selecciona la Categoria</Label>
                    <CreatableSelect
                      isMulti
                      name="category"
                      onChange={(values) => setCategory(values)}
                      placeholder={"Selecciona o Crea una Categoria"}
                      options={categoryQ}
                      styles={{ menu: (provided) => ({...provided, zIndex: 999}) }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="">Tipo de Reactivo</Label>
                    <Select
                      name="typeQ"
                      onChange={(values) => setTypeQuestion(values)}
                      placeholder={"Selecciona.."}
                      options={typesQ}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="">Elabora la descripcion o la pregunta</Label>
                    <JoditEditor
                      config={config.configEditor}
                      value={description}
                      onChange={(model) => setDescription(model)}
                      name="editor"
                    />
                  </FormGroup>
                </Col>
              </Row></>
     );
}
 
export default QuestionHead;