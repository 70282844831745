import React from "react";
import HeaderSmall from "../../components/Headers/HeaderSmall";
import { Container, Row, Card, CardHeader, Button, CardBody } from "reactstrap";

const Assessment = ({ history }) => {
  return (
    <>
      <HeaderSmall />
      <Container className="mt-2" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Listado de Evaluaciones</h3>
                  </div>
                  <div className="col">
                    <Button
                      color="primary"
                      className="float-right"
                      onClick={() =>
                        history.push("/dashboard/evaluations/create")
                      }
                    >
                      <i className="fa fa-plus"></i>
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <h4>Listado</h4>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Assessment;
