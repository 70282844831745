import React, { useState, useCallback } from "react";
import { v4 as uuid } from 'uuid';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { firestoreConnect, useFirestore, } from "react-redux-firebase";
import { compose } from "redux";
import { Container, Row, Col, Card, CardBody, CardFooter, Form, Button } from "reactstrap";
import HeaderSmall from "../../components/Headers/HeaderSmall";
import Multiple from '../../components/AnswersTypes/Multiple';
import Columns from '../../components/AnswersTypes/Columns';
import Paragraph from '../../components/AnswersTypes/Paragraph';
import QuestionHead from '../../components/Questions/QuestionHead';
import { useToasts } from 'react-toast-notifications';
import { saveNewCategory, saveQuestion } from "../../store/actions/questions";

const CreateQuestion = ({ types, saveNewCategoryProp, saveQuestionProp, t, history, catQuestion}) => {
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState([]);
  const [typeQuestion, setTypeQuestion] = useState(null);
  const [answers, setAnswers] = useState({})
  const firestore = useFirestore();
  const { addToast } = useToasts();
  const auth = useSelector(state => state.firebase.auth);  
  const callbackData = useCallback(data => {
    setAnswers(data);
  },[]);

  const renderTypeAnswer = () => {
    if( typeQuestion !== null){
        const { value } = typeQuestion;
        switch (value) {
            case 1:
            case 2:
                return <Multiple t={t} multiple={value === 1} callback={callbackData} />
            case 4:
                return <Paragraph t={t} callback={callbackData}/>
            case 5:
                return <Columns t={t} callback={callbackData}/>
            default:
                return null;
        }
    }
    return null;
  }

  const onSubmit = (e) => {
      e.preventDefault();

      if(category.length === 0){
        addToast('La categoria es un campo obligatorio. ', { appearance: 'error'});
        return;
      }
      if(description.length === 0){
        addToast('La descripcion es un campo obligatorio.', { appearance: 'error'});
        return;
      }
      if(typeQuestion === null){
        addToast('El tipo de pregunta es un campo obligatorio.', { appearance: 'error'});
        return;
      }

      if(validationAnswer(typeQuestion.value, answers)){
        let arrCat = [];
        for (let cat of category){
          const { __isNew__, value, label } = cat;
          if(__isNew__){
            let uuidValue = uuid();
            saveNewCategoryProp({ value: uuidValue, label, user: auth.uid }, firestore)
            arrCat.push(uuidValue);
          }else{
            arrCat.push(value)
          }
        }
        const payloadSave = {
          category: arrCat,
          description,
          type: typeQuestion.value,
          answers: Object.keys(answers).length === 0 ? {} : answers.data,
          created_by: auth.uid,
          active: true,
        };

        saveQuestionProp(payloadSave, firestore)
        .then(result => {
          addToast('Se guardo correctamente !',{ appearance: 'success' })
          setTimeout(() => {
            history.goBack();
          },1000)
        })
        .catch(err => addToast('Surgio un error al guardar, favor de intentar de nuevo',{ appearance: 'error' }))
      }
  }

  const validationAnswer = (type, answers) => {
    let isValid;
    const { data } = answers;
    switch (type) {
      case 1:
      case 2:
        const { options } = data;
        isValid = options.filter(x => x.selected=== true).length > 0;
        break;
      case 3:
        isValid = true;
        break;
      case 4:
        const { respuestas } = data;
        isValid = respuestas.length > 0;
        if(isValid){
          for(let r of respuestas ){
            isValid = r.value !== "";
          }
        }
        break;
      case 5:
        const { options: opts } = data;
        const optsSave = opts.filter(x => x.isNew === false);
        isValid = optsSave.length > 0;
        if(isValid){
          for( let o of optsSave) {
            isValid = o.question !== "" && o.answer !== "";
          }
        }
        break;
      default:
        isValid = false;
        break;
    }

    if(!isValid) {
      addToast('Debe de colocar una respuesta valida a la pregunta', { appearance: 'error' });
    }

    return isValid;
  }

  return (
    <>
      <HeaderSmall />
      <Container className="mt-2" fluid>
        <Card>
          <Form onSubmit={onSubmit}>
            <CardBody>
              <QuestionHead category={catQuestion} types={types} setCategory={setCategory} setTypeQuestion={setTypeQuestion} description={description} setDescription={setDescription} />
              <Row>
                  <Col md={12}>
                      {renderTypeAnswer()}
                  </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <div className="col">
                  <Button color="success" type="submit" className="float-right ml-2">
                    Guardar
                  </Button>
                  <Button color="danger" type="button" className="float-right" onClick={() => history.goBack()}>
                    Cancelar
                  </Button>
                </div>
              </Row>
            </CardFooter>
          </Form>
        </Card>
      </Container>
    </>
  );
};

const mapCollectionFirebase = firestoreConnect((props) => [
  { collection: "cat_question_types", orderBy: ['value', 'asc'] },
  { collection: "cat_questions", orderBy: ['label', 'asc'] },
]);

const mapStateToProps = (state) => ({
  types: state.firestore.data.cat_question_types,
  catQuestion: state.firestore.data.cat_questions,
});

const mapDispatchToProps = (dispath) => ({
  saveNewCategoryProp: (data, firestore) =>
    dispath(saveNewCategory(data, firestore)),
  saveQuestionProp: (data, firestore) =>
    dispath(saveQuestion(data, firestore)),
});

export const CreateQuestionComp = CreateQuestion;
const CreateQuestionRouter = withTranslation()(withRouter(CreateQuestionComp));
export default compose( mapCollectionFirebase, connect(mapStateToProps, mapDispatchToProps))(CreateQuestionRouter);
