import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase'

const ProtectedRoute = ({ ComponentRender, ...rest }) => {
    const auth = useSelector(state => state.firebase.auth)
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoaded(auth) && !isEmpty(auth) ? (
                    <ComponentRender {...rest} />
                ) : (
                <Redirect
                    to={{
                    pathname: "/access/login",
                    state: { from: location }
                    }}
                />
                )
            }
        />
    );
}
 
export default ProtectedRoute;