import React, { useState, useEffect } from "react";
import { Row, Col, Input, Container } from "reactstrap";
import ReactDatetime from "react-datetime";

const GeneralData = ({ callback, defaultData }) => {
  const { titulo: tituloProp, startDate: starDateProp, endDate: endDateProp, random: randomProp, send: sendProp } = defaultData;
  const [titulo, setTitulo ] = useState(tituloProp ? tituloProp : '');
  const [ startDate, setStart] = useState(starDateProp ? starDateProp : new Date());
  const [ endDate, setEnd] = useState(endDateProp ? endDateProp : new Date());
  const [ random,  setRandom] = useState(randomProp ? randomProp : false);
  const [ send,  setSend] = useState(sendProp ? sendProp : false);
  useEffect(() => {
    const payload = {
      generalDataComp: {
        titulo,
        startDate,
        endDate,
        random,
        send
      }
    };
    callback(payload)
  },[ titulo, startDate, endDate, random, send , callback])

  return (
    <Container fluid className="mt-2">
      <Row className="text-center">
        <Col>
          <h3>Datos Generales</h3>
        </Col>
      </Row>
      <Row className="d-flex align-items-center mt-2">
        <Col xs={3} className="text-right">
          Titulo
        </Col>
        <Col xs={6}>
          <Input type="text" placeholder="Titulo..." value={titulo} onChange={e => setTitulo(e.target.value)} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center mt-3">
          Fecha de Aplicación
        </Col>
        <Col xs={{ size: 4, offset: 2 }}>
          <ReactDatetime
            value={startDate}
            onChange={(e) => setStart(e)}
            inputProps={{
              placeholder: "Selecciona Fecha de Inicio",
            }}
          />
        </Col>
        <Col xs={{ size: 4 }}>
          <ReactDatetime
            value={endDate}
            onChange={(e) => setEnd(e)}
            inputProps={{
              placeholder: "Selecciona Fecha de Termino",
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={6}>
          <Row>
            <Col xs={6} className="text-center">
              Respuestas Aleatorios
            </Col>
            <Col xs={6}>
              <label className="custom-toggle">
                <input type="checkbox" checked={random} onChange={e => setRandom(e.target.checked)} />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={6} className="text-center">
              Notificar al Alumno
            </Col>
            <Col xs={6}>
              <label className="custom-toggle">
                <input type="checkbox" checked={send} onChange={e => setSend(e.target.checked)} />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneralData;
