import React from 'react'
import Question from './Question';

const QuestionList = ({questions, isCheckRender, isAnswerSlice, onCheckedItem}) => {

    return ( 

        <div className="row">
            {questions.map(question => (
                <div className="col-md-4 mt-4 custom-padding"
                    key = {question.id}>
                    <Question
                        question = {question}
                        isCheckRender = {isCheckRender}
                        isAnswerSlice = {isAnswerSlice}
                        onCheckedItem={onCheckedItem}
                    />
                </div>
            ))}
        </div>
        
     );
}
 
export default QuestionList;