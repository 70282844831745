import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Container, Table, Button } from "reactstrap";
import { firestoreConnect } from "react-redux-firebase";
import { isLoaded } from 'react-redux-firebase';
import Select from 'react-select';
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import styles from './styles';
import { validateEmail } from '../../utils/RegexHelper'

const AssesGroups = ({ groups, addToast }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [groupsOpt, setGroups] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [newParticipants, setNewParticipants] = useState([]);
    const auth = useSelector(state => state.firebase.auth);  

    useEffect(() => {
        if (isLoaded(groups) && groups !== null) {
          const newvalues = Object.entries(groups).filter(f => f[1].created_by === auth.uid).map(x => {
            return { ...x, value: x[0], label: x[1].name }
          });
          setGroups(newvalues);
        }
    }, [groups, auth]);

    const selectedGroup = (item) => {
        setParticipants(item[1].participants);
    }

    const addNewParticipant= () => {
        if(name === "" || !validateEmail(email)){
            addToast('Favor de completar la información correctamente', { appearance: 'error' });
            return;
        }

        setNewParticipants(state => {
            return [...state, { name, email }]
        })
    }

    return ( 
        <Container fluid className="mt-2">
            <Row className="text-center">
                <Col>
                    <h3>Grupo</h3>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={4}>
                    <Select
                        name="typeQ"
                        placeholder={"Selecciona un grupo"}
                        options={groupsOpt}
                        onChange={selectedGroup}
                        />
                </Col>
                <Col xs={3}>
                        <Input type="text" placeholder="Nombre" value={name} onChange={e => setName(e.target.value)} />
                </Col>
                <Col xs={3}>
                    <Input type="email" placeholder="Correo Electronico" value={email} onChange={e => setEmail(e.target.value)} />
                </Col>
                <Col xs={2}>
                    <Button type="button" color="info" onClick={addNewParticipant}>
                        <i className="fa fa-plus"></i>
                        </Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col xs={12}>
                    <div style={styles.containerScroll}>
                        <Table hover striped>
                            <thead>
                                <tr className="text-center">
                                    <th>Nombre Completo</th>
                                    <th>Correo Electronico</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                newParticipants && (
                                    newParticipants.map((p, i) => (
                                        <tr key={i} className="text-center table-success"><td>{p.name}</td><td>{p.email}</td></tr>  
                                    ))
                                )
                            }
                            {
                                participants && (
                                    participants.map((p, i) => (
                                        <tr key={i} className="text-center"><td>{p.name}</td><td>{p.email}</td></tr>  
                                    ))
                                )
                            }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
     );
}

const mapCollectionFirebase = firestoreConnect((props) => [
    { collection: "groups", orderBy: ['created_date', 'desc'] }
  ]);
  
  const mapStateToProps = (state) => ({
    groups: state.firestore.data.groups
  });
 
 
  export default compose( mapCollectionFirebase, connect(mapStateToProps, null))(AssesGroups);
