import React, { useState, Fragment } from 'react'

import Categories from '../../components/Questions/read/Categories';
import QuestionBody from '../../components/Questions/read/QuestionBody';
import ModalBodyQuestion from '../../components/Questions/read/ModalBodyQuestion';
import GenericModal from '../../components/GenericModal';
import {Card, CardBody} from "reactstrap";

const Question = ({question, isCheckRender, isAnswerSlice, onCheckedItem}) => {

    const{description, type, answers, id, checked} = question;
    
    const openModal = () => {
        setOpen(true);
    }

    const closeModal = () => {
        setOpen(false);
    }

    const[isOpen, setOpen] = useState(false);

    return ( 
        <Fragment>
            <Card className="card-stats mb-4 mb-lg-0 rounded-pill card-margin quest-cards-bg">
                        
                {   isCheckRender ?
                    <div className="container">
                        <div className="row justify-content-end question-check">
                            <div className="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id={'check_'+ id}
                                        type="checkbox"
                                        onChange={e => onCheckedItem(e.target.checked, id)}
                                        checked={checked ? checked : false}
                                    />
                                <label className="custom-control-label" htmlFor={'check_'+ id}>
                                    
                                </label>
                            </div>
                        </div>
                    </div> : null
                }

                <CardBody className="card-body-fit text-justify text-muted text-sm mt-0">
                
                    <div className="scroll">
                        {/* -------------------- QUESTION ---------------  */}
                        <QuestionBody 
                            isAnswerSlice = {isAnswerSlice}
                            description = {description} 
                            type = {type}
                            answer = {answers}
                        />
                    </div>

                    {/* -------------------- BOTTOM - CATEGORIES - MODAL BUTTON ---------------  */}
                    <div className="row justify-content-end align-items-center">
                        <Categories
                            categories = {question.category}
                            isAnswerSlice = {isAnswerSlice}
                        />

                        <span className="text-success ml-1 mt-2 text-lg cursor" onClick={() => openModal()}>
                            <i className="fa fa-plus-circle" />
                        </span>
                    </div>
                </CardBody>
            </Card>

            <GenericModal
                open = {isOpen}
                closeModal = {closeModal}
                title=""
                body = {
                    <ModalBodyQuestion
                        categories = {question.category}
                        description = {description} 
                        type = {type}
                        answer = {answers}
                        isAnswerSlice = {false}
                    />
                }
            />
        </Fragment>
     );
}
 
export default Question;