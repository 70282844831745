import React, { useState, useEffect } from "react";
import { v4 as uuid } from 'uuid';
import OptionsMultiple from './OptionsMultiple';
import {
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from "reactstrap";

const Multiple = ({ t, multiple, callback }) => {
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState('');

  useEffect(() => {
    setOptions([]);
  },[multiple])

  useEffect(() => {
    callback({ data: { options }});
  },[options, callback])

  const addAnswer = () => {
      if (input === '') return;

      setInput('');
      setOptions([...options, {name: input, id: uuid(), selected: false }]);
  }

  const pressEnter = (e) => {
    if(e.key === "Enter"){
      e.preventDefault();
      addAnswer();
    }
  }

  const deleteOption = (e, id) => {
    e.preventDefault();
    let stateTemp = [...options];
    stateTemp = stateTemp.filter(x => x.id !== id);
    setOptions(stateTemp);
  }

  const setSelected = (id) => {
    let stateTemp = [...options];
    stateTemp = stateTemp.map(m => {
      if(multiple){
        return {...m, selected: m.id === id ? !m.selected : m.selected }
      }else {
        return {...m, selected: m.id === id ? true : false}
      }
    })
    setOptions(stateTemp);
  }
  return (
    <>
      <Row className="mb-3">
        <Col lg="6" md="12">
          <InputGroup>
            <Input type="text" placeholder="Escribe tu respuesta" onKeyDown={pressEnter} onChange={e => setInput(e.target.value)} value={input} />
            <InputGroupAddon addonType="append">
              <Button color="primary" type="button" onClick={() => addAnswer()}><i className="fa fa-plus"></i></Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
      <Row>
          {options.map(a => (
           <OptionsMultiple key={a.id} name={a.name} selected={a.selected} id={a.id} deleteOption={deleteOption} setSelected={setSelected} />
          ))}
      </Row>
    </>
  );
};

export default Multiple;
