import React, { useEffect, useRef } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector, connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { isLoaded, isEmpty, firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { Container } from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import Sidebar from "../components/Sidebar/Sidebar";

import routes from '../routes';
import Loader from '../components/Loader';

// actions redux
import { loadDataUserAction } from '../store/actions/auth';

function Admin(props) {
    const refInput = useRef(null);
    const auth = useSelector(state => state.firebase.auth);
    const { users, history, saveDataUser } = props;
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        if(refInput !== null && refInput.current !== null){
          refInput.current.scrollTop = 0;
        }
        if (isLoaded(auth) && !isEmpty(auth) && isLoaded(users)){
          let exist = false;
          Object.keys(users).forEach(key => {
            if (users[key].email === auth.email) {
              exist = true;
              saveDataUser({...users[key], ...auth});
              return;
            }
          })

          if(!exist){
            history.push('/access/register');
          }
        }
    },[auth, users, history, saveDataUser]);


    const getRoutes = routes => {
        return routes.map((prop, key) => {
          if (prop.layout === "/dashboard") {
            return (
              <Route
                exact
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          } else {
            return null;
          }
        });
      };
    const getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
          if (
            props.location.pathname.indexOf(
              routes[i].layout + routes[i].path
            ) !== -1
          ) {
            return routes[i].name;
          }
        }
        return "Brand";
    };

    if(!isLoaded(auth) || !isLoaded(users)){
        return <Loader />
    }
    return ( 
        <>
            <Sidebar
            {...props}
            routes={routes}
            logo={{
                innerLink: "/dashboard/index",
                imgSrc: require("../assets/img/brand/argon-react.png"),
                imgAlt: "..."
            }}
            />
            <div className="main-content" ref={refInput}>
            <AdminNavbar
                {...props}
                brandText={getBrandText(props.location.pathname)}
            />
            <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/dashboard/index" />
            </Switch>
            <Container fluid>
                <AdminFooter />
            </Container>
            </div>
        </>
     );
}

export const mapStateToProps = state => ({
  users: state.firestore.data.users,
  dataUser: state.auth.data,
});

export const mapDispatchToProps = dispath => ({
  saveDataUser: data => dispath(loadDataUserAction(data))
}); 

export const mapCollectionFirebase = firestoreConnect((props) => [
  { collection: 'users'}
])
 
export const AdminComp = withRouter(Admin)
const RouterComp = withTranslation()(AdminComp)
export default compose(mapCollectionFirebase,connect( mapStateToProps, mapDispatchToProps ))(RouterComp);