import { SIGNUP_ERROR, SIGNUP_SUCCESS, SIGNOUT_ERROR, SIGNOUT_SUCCESS } from './../types';

export const initialState = {
    data: {},
    error: false,
}

export default ( state = initialState, action = null) => {
    switch (action.type) {
        case SIGNUP_SUCCESS:{
            return {...state, data: action.payload, error: false }
        }
        case SIGNOUT_ERROR:
        case SIGNUP_ERROR:{
            return {...state, data: {}, error: true }
        }
        case SIGNOUT_SUCCESS:{
            return { ...state, data: {}, error: false }
        }
        default:
            return state;
    }
}