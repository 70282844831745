import React from 'react'
import { Row } from "reactstrap";
import shortId from 'shortid'

const MultipleAnswers = ({answer, isAnswerSlice}) => {
    
    let i = 0;
    const buildList = (option) => {

        if(isAnswerSlice === true){
            if (i<2){
                i++;
                return addLi(option);
            }
        }else
            return addLi(option);
                
    }

    const addLi = (option) => {
        let list = (
            <li 
                className={option.selected ? 'answer' : null}
                key={shortId.generate()}>
            
                {option.name} 
            </li>
        )

        return list;
    }

    return (  
        <Row>
            <ul className="text-muted mb-0 mt-3 text-sm">
                {
                    answer.options.map( option => (
                        buildList(option)
                        
                    ))
                }
            </ul>
        </Row>
    );
}
 
export default MultipleAnswers;