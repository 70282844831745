import React, {useState, Fragment, useRef} from 'react';
import ReactDatetime from "react-datetime";
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase'
import {initializeValidator} from '../utils/FormValidator'
import { useToasts } from 'react-toast-notifications'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
  } from "reactstrap";

const Register = ({history}) => {


  const fireStore = useFirestore();
  const firebase = useFirebase();

  //obtener el usuario autenticado, si es que existe
  const auth = useSelector(state => state.firebase.auth);
  const userExists = !auth.isEmpty;
  // console.log(auth);

  //INIT AND CONFIG OF FORM VALIDATOR
  const [, forceUpdate] = useState();
  const customValidator = {
    passwords: {
      message: 'Las contraseñas no coinciden',
      rule: (val, params, validator) => {
        return val === params.shift() ? true : false;
      },
    },
    passRegex: {
      message: 'La contraseña debe tener al menos una mayúscula, una minúscula, un dígito y una longitud de 8 caracteres.',
      rule: (val, params, validator) => {
        var pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
        return pattern.test(val);
      },
    }
  };
  const simpleValidator = useRef(initializeValidator(forceUpdate, customValidator));

  //ADD USER INFORMATION TO THE STATE
  const [info, updateInfo] = useState({
    email : userExists ? auth.email : '',
    pass: '',
    confirmPass: '',
    name : (userExists && auth.displayName !== null) ? auth.displayName : '',
    institution : '',
    profesion : '',
    birthday : '',
    gender : 'F'
  });

  //EXTRACT INFORMATION OF THE STATE
  const {email, pass, confirmPass, name, institution, profesion, birthday, gender} = info;

  //FUNCTION THAT UPDATES THE USER INFORMATION WHEN ONCHANGE METHOD IS TRIGGERED
  const onChangeValue = e => {
    updateInfo({
      ...info,
      [e.target.name]: e.target.value
    })
  }

  //FUNCTION THAT UPDATES THE FIEL BIRDTHDAY
  const onDateChange = (moment) => {
    updateInfo({
      ...info,
      birthday: moment.format('YYYY-M-D')
    })
  }

  //TOAST NOTIFICATIONS
  const { addToast } = useToasts();

  //FUNCTION THAT IS CALLED ON FORM SUBMIT
  const doRegister = e => {
    e.preventDefault();

    //CHECK FORM VALIDATION
    if (simpleValidator.current.allValid()) {

      //IF THE USER EXISTS, SAVE THE ADITIONAL INFO ON DB
      if(userExists){
        saveUser();
      }else{
        //IF THE USER DOESNT EXIST, CREATE THE USER CREDENTIALS ON FIREBASE FIRST
        saveAuthUser()
        .then(result => {
          saveUser();//SAVE ADITIONAL INFO
        }).catch(error => {
          console.log(error);
          switch(error.code) {
            case 'auth/email-already-in-use':
              addToast('El email ya se encuentra registrado.', {appearance: 'error'});
              break;
            case 'auth/account-exists-with-different-credential':
              addToast('La cuenta ya está rgeistrada.', {appearance: 'error'});
              break;
            case 'auth/credential-already-in-use':
              addToast('Las credenciales ya estám registradas.', {appearance: 'error'});
              break;
            default:
              addToast('Ocurrió un error al crear su usuario. Inténtelo más tarde.', {appearance: 'error'});
          }

        });
      }
    } else {
      //RENDER VALIDATOR MESSAGES
      simpleValidator.current.showMessages();
    }
  }

  //SAVE THE USER INFORMATION ON DB
  const saveUser = () =>{
    let birthdayDate = firebase.firestore.Timestamp.fromDate(new Date(birthday));
    fireStore.collection('users').add({email, name, institution, profesion,  birthdayDate, gender})
    .then(result => {
      addToast('El usuario se guardó correctamente.', { appearance: 'success'});
      //REDIRECT TO THE INDEX
      history.push('/dashboard/index')
    })
    .catch(err => {
      console.log(err);
      addToast('Ocurrió un error al guardar su información. Inténtelo más tarde.', {appearance: 'error'});
    });
  }

  //CREATE THE USER CREDENTIALS ON FIREBASE
  const saveAuthUser = () => {
    return firebase.createUser(
      { email, password:pass }
    )
  }

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-2">
            <div className="text-center">
              {userExists ? <h2>informaci&oacute;n Adicional</h2> : <h2>Registro</h2>}
            </div>
          </CardHeader>
          
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={doRegister}>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText> 
                  </InputGroupAddon>
                  <Input placeholder="Email" type="text" autoComplete="new-email" name="email" value={userExists ? auth.email : email} onChange={onChangeValue} disabled={userExists ? true : false}/>
                </InputGroup>
              </FormGroup>
              {simpleValidator.current.message('Email', email, 'required|email', { className: 'validator-error' })}

              {userExists ? null : 
                <Fragment>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Contraseña" type="password" autoComplete="new-password" name="pass" value={pass} onChange={onChangeValue}/>
                    </InputGroup>
                  </FormGroup>
                  {simpleValidator.current.message('Contraseña', pass, 'required', { className: 'validator-error' })}

                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Confirmar constraseña" type="password" autoComplete="new-password" name="confirmPass" value={confirmPass} onChange={onChangeValue}/>
                    </InputGroup>
                  </FormGroup> 
                  {simpleValidator.current.message('Confirmar constraseña', confirmPass, 'required|passRegex|passwords:' + pass, { className: 'validator-error' })}
                </Fragment>
              }

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder={(userExists && auth.displayName !== null)? auth.displayName : "Nombre"} type="text" name="name" value={name} onChange={onChangeValue}/>
                </InputGroup>
              </FormGroup>
              {simpleValidator.current.message('Nombre', name, 'required', { className: 'validator-error' })}

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Institución" type="text" name="institution" value={institution} onChange={onChangeValue}/>
                </InputGroup>
              </FormGroup>
              {simpleValidator.current.message('Institucion', institution, 'required', { className: 'validator-error' })}

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-briefcase-24" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Profesión" type="text" name="profesion" value={profesion} onChange={onChangeValue}/>
                </InputGroup>
              </FormGroup>
              {simpleValidator.current.message('profesion', profesion, 'required', { className: 'validator-error' })}

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Fecha de Nacimiento",
                    }}
                    timeFormat={false}
                    dateFormat="D-M-YYYY"
                    value={birthday}
                    name="birthday"
                    onChange={onDateChange}
                    closeOnSelect={true}
                  />
                </InputGroup>
              </FormGroup>
              {simpleValidator.current.message('Fecha de Nacimiento', birthday, 'required', { className: 'validator-error' })}

              <Row>
                <label className="">
                  Sexo
                </label>

              </Row>
              <Row>
                <Col className="col-sm">
                  <div className="custom-control custom-control-alternative custom-radio mb-3">
                    <input
                      className="custom-control-input"
                      id="sexRadioF"
                      type="radio"
                      name="gender"
                      value="F"
                      checked={gender === "F"}
                      onChange={onChangeValue}
                    />
                    <label className="custom-control-label" htmlFor="sexRadioF">
                      Femenino
                    </label>
                  </div>
                </Col>
                <Col className="col-sm">
                  <div className="custom-control custom-control-alternative custom-radio mb-3">
                    <input
                      className="custom-control-input"
                      id="sexRadioM"
                      type="radio"
                      name="gender" 
                      value="M"
                      checked={gender === "M"}
                      onChange={onChangeValue}
                    />
                    <label className="custom-control-label" htmlFor="sexRadioM">
                      Masculino
                    </label>
                  </div>
                </Col>
              </Row>
              
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
 
export default withRouter(Register);