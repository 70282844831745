import React from 'react';
import { Col, UncontrolledTooltip, Row, Card, CardBody} from 'reactstrap';

const OptionsMultiple = ({ name, selected, id, deleteOption, setSelected }) => {
    return ( 
        <Col lg="3" md="6" sm="6">
        <Card className={`${selected ? 'active-boder' : ''}`} id="tooltip982655500">
          <CardBody>
            <Row>
              <Col lg="9" md="9" sm="12" onClick={() => setSelected(id)}>
                {name}
              </Col>
              <Col lg="3" md="3" sm="12">
                <a href="#!" onClick={e => deleteOption(e, id)}><i className="fa fa-trash"></i></a>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip982655500">
          Respuesta Correcta
        </UncontrolledTooltip> 
      </Col>
     );
}
 
export default OptionsMultiple;