import React, { useState, useCallback } from "react";
import { Container, Row, Card, CardBody, CardFooter, Form, Button } from "reactstrap";
import { useToasts } from 'react-toast-notifications';
import HeaderSmall from "../../components/Headers/HeaderSmall";
import GeneralData from "./GeneralData";
import Stepper from './Stepper';
import AssesQuestions from "./AssesQuestions";
import AssesGroups from "./AssesGroups";
import { isEqual } from 'lodash';
import { dateDiff } from '../../utils/helper';

const CreateAssessment = ({ history }) => {
  const [generalData, setGeneralData] = useState({});
  const [assesQuestion, setQuestion] = useState([]);
  const [activePage, setActivePage] = useState(0)
  const [steps, setStep] = useState([
    {
      value: 0,
      name: "",
      icon: "ni ni-bullet-list-67",
      active: true,
    },
    {
      value: 1,
      name: "",
      icon: "ni ni-atom",
      active: false,
    },
    {
      value: 2,
      name: "",
      icon: "ni ni-circle-08",
      active: false,
    },
  ]);
  const { addToast } = useToasts();

  const formDataCallback = useCallback(data => {
    const { generalDataComp,questionsAsses } = data;
    if(generalDataComp !== undefined && !isEqual(generalData, generalDataComp)){
      setGeneralData(generalDataComp);
    }

    if(questionsAsses !== undefined && !isEqual(assesQuestion, questionsAsses)){
      setQuestion(questionsAsses);
    }
  },[generalData, assesQuestion]);

  const validateInputs = (activeIdx) =>{
    let response = { isValid: false,  message: '' }
    switch (activeIdx) {
      case 0:
        const { titulo, startDate, endDate } = generalData;
        if (titulo === "") {
          response.message = 'Favor de colocar un titulo a la evaluación'
        } else if ( startDate > endDate || dateDiff(new Date(), startDate) < 60){
          response.message = 'Validar que la fecha de aplicacion sea correcta, y con una diferencia de 60 min a la fecha actual';
        } else {
          response = { isValid: true,  message: '' }
        }
        break;
      case 1:
        if(assesQuestion.length === 0) {
          response.message = 'Seleccionar una o mas preguntas a la evaluacion';
        } else {
          response = { isValid: true,  message: '' }
        }
        break;
      default:
        response.isValid = false;
        break;
    }
    return response;
  }

  const changeTab = (e, option) => {
    e.preventDefault()
    let active = steps.find(x => x.active === true).value;
    const { isValid, message } = validateInputs(active);
    if(!isValid && option === "add"){
      addToast(message, {appearance: 'error'})
      return;
    }
    if(option === 'add'){
      active ++;
    }else {
      active --;
    }
    let tempStep = [];
    for(let t of steps){
      if(t.value === (active)){
        tempStep.push({...t, active : true})
      }else {
        tempStep.push({...t, active : false })
      }
    }
    setActivePage(active)
    setStep(tempStep);
  }

  const submitForm = (e) => {
    e.preventDefault();

  }

  const renderStep = () => {
    switch (activePage) {
      case 0:
        return <GeneralData callback={formDataCallback} defaultData={generalData} />;
      case 1:
        return <AssesQuestions callback={formDataCallback} defaultData={assesQuestion} />;
      case 2:
        return <AssesGroups callback={formDataCallback} addToast={addToast} />;
      default:
        return <div></div>;
    }
  };
  return (
    <>
      <HeaderSmall />
      <Container className="mt-2" fluid>
        <Card>
          <Form onSubmit={submitForm}>
            <CardBody>
                <Stepper arraySteps={steps} activeStep={activePage} />
                <Row>
                    {renderStep()}
                </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <div className="col">
                  { activePage === 2 ?
                  (
                  <Button
                    color="success"
                    type="submit"
                    className="float-right ml-2"
                    >
                    Guardar
                  </Button>
                  ) : (
                    <Button
                    color="primary"
                    type="submit"
                    className="float-right ml-2"
                    onClick={(e) => changeTab(e,'add')}
                    >
                    Siguiente
                  </Button>
                  )
                }
                  <Button
                    color="danger"
                    type="button"
                    className="float-right"
                    onClick={activePage === 0 ? () => history.push('/dashboard/evaluations') : (e) => changeTab(e,'degree')}
                  >
                    Regresar
                  </Button>
                </div>
              </Row>
            </CardFooter>
          </Form>
        </Card>
      </Container>
    </>
  );
};

export default CreateAssessment;
