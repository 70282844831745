import React, { Fragment, useState } from "react";
import { Input, Button } from "reactstrap";

const AnswerRow = ({
  isNew,
  question,
  answer,
  saveAction,
  deleteAction,
  id,
}) => {
  const [questionState, setQuestion] = useState("");
  const [answerState, setAnswer] = useState("");

  const validateInput = (e) => {
    e.preventDefault();
    if(questionState !== "" && answerState !== ""){
      saveAction(questionState, answerState, id)
    }
  }
  return (
    <tr className="text-center">
      {isNew ? (
        <Fragment>
          <td>
            <Input
              type="text"
              placeholder="Coloca aqui la pregunta"
              onChange={(e) => setQuestion(e.target.value)}
              value={questionState}
              onBlur={validateInput}
            />
          </td>
          <td>
            <Input
              type="text"
              placeholder="Coloca aqui la respuesta"
              onChange={(e) => setAnswer(e.target.value)}
              value={answerState}
              onBlur={validateInput}
            />
          </td>
          <td className="align-middle">
            <Button
              color="warning"
              className="btn-sm"
              onClick={() => deleteAction(id)}
            >
              <i className="fa fa-times"></i>
            </Button>
          </td>
        </Fragment>
      ) : (
        <Fragment>
          <td>{question}</td>
          <td>{answer}</td>
          <td>
            <Button
              color="warning"
              className="btn-sm"
              onClick={() => deleteAction(id)}
            >
              <i className="fa fa-times"></i>
            </Button>
          </td>
        </Fragment>
      )}
    </tr>
  );
};

export default AnswerRow;
