import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { Translation } from 'react-i18next';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import firebase from './services/firebase';
import i18nInit from './i18nInit';

const rrfProps = {
  firebase,
  config: { userProfile: 'users' },
  dispatch: store.dispatch,
  createFirestoreInstance,
}

i18nInit();

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Translation ns="onlineTestTranslation">
      { (t, { i18n }) => (<App t={t} i18n={i18n} />) }
      </Translation>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

//destroy session
// firebase.auth().signOut();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
