export const saveNewCategory = (value, firestore) => {
    return (dispatch) => {
        try {
            firestore.collection('cat_questions')
            .add({ 
                label: value.label,
                value: value.value,
                created_by: value.user,
                created_date: firestore.Timestamp.fromDate(new Date())
            });
        } catch (error) {
            console.log(error);
        }
    }
}

export const saveQuestion = (payload, firestore) => {
    const payloadNew = { ...payload, created_date: firestore.Timestamp.fromDate(new Date()), active: true}
    return (dispatch) => {
        try {
            return firestore.collection('questions')
            .add(payloadNew);
        } catch (error) {
            console.log(error);
        }
    }
}