import React, {Fragment} from 'react'
import QuestionBody from '../read/QuestionBody';
import Categories from '../read/Categories';

const ModalBodyQuestion = ({description, type, answer, isAnswerSlice, categories}) => {
    return ( 
        <Fragment>
            <QuestionBody 
                isAnswerSlice = {isAnswerSlice}
                description = {description} 
                type = {type}
                answer = {answer}
            />

            <Categories
                categories = {categories}
                isAnswerSlice = {isAnswerSlice}
            />
        </Fragment>
     );
}
 
export default ModalBodyQuestion;