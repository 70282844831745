import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import "firebase/auth";
import 'firebase/database';
import "firebase/firestore";

// Your app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDtqJEO3LGtbQlcjgEOfvpHbEk4CVU3ZPU",
    authDomain: "pardock-personal.firebaseapp.com",
    databaseURL: "https://pardock-personal.firebaseio.com",
    projectId: "pardock-personal",
    storageBucket: "pardock-personal.appspot.com",
    messagingSenderId: "33210369377",
    appId: "1:33210369377:web:64cafa1e5549f51c4c3120",
    measurementId: "G-54XB6MTTQS"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.firestore()

// Finally, export it to use it throughout your app
export default firebase;