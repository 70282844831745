import React from "react";
import { Container, Row, Col } from 'reactstrap'

const Loader = () => {
  return (
      <Container>
          <Row>
              <Col md={12} lg={12}  className="mt-5 text-center">
                <div className="sk-chase text-center">
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                </div>
              </Col>
          </Row>
    </Container>
  );
};

export default Loader;
