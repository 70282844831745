import React from "react";
import { connect, useSelector } from "react-redux";
import { useFirebase } from 'react-redux-firebase';
import { DropdownMenu, DropdownItem, UncontrolledDropdown,
  DropdownToggle, Navbar, Nav, Container, Media } from "reactstrap";
  import { logoutAction } from '../../store/actions/auth';

const AdminNavbar = ({ logout, brandText }) => {
  const firebase = useFirebase();
  const userData = useSelector(state => state.auth.data);
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <label className="mb-0 d-none d-lg-inline-block header-title">
              {brandText}
            </label>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt={userData.name}
                        src={userData.image}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold font-italic">
                        {userData.name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem onClick={() => logout(firebase)}>
                    <i className="ni ni-user-run" />
                    <span>Cerrar Sesión</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
}

const mapDispatchToProps = dispatch => ({
  logout: data => dispatch(logoutAction(data))
})

export default connect(null,mapDispatchToProps)(AdminNavbar);
