import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { ToastProvider } from 'react-toast-notifications'

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import Auth from './layout/Auth';
import Admin from './layout/Admin';
import ProtectedRoute from './components/ProtectedRoute';
import Loader from './components/Loader';

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <Loader />;
  return children
}

const App = (props) => {
  return (
    <AuthIsLoaded>
      <ToastProvider autoDismiss autoDismissTimeout={7000}>
        <Router>
          <Switch>
            <Route path="/access" render={() => <Auth {...props} />} />
            <ProtectedRoute path="/dashboard" ComponentRender={Admin} {...props}/>
            <Redirect from="**" to="/access/login" />
          </Switch>
        </Router>
      </ToastProvider>
    </AuthIsLoaded>
  );
}

export default App;
