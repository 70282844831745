import 'moment/locale/es';
import moment from 'moment';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import tranlations from './config/translations';
import i18next from 'i18next';

const i18nInit = () => {
    const i18nInstance = (
        i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: 'es-MX',
            defaultLng: 'es-MX',
            ns: ['onlineTestTranslation'],
            defaultNS: 'onlineTestTranslation',
            debug: false,
            react: {
                wait: false,
                bindI18n: 'languageChanged loaded',
                bindStore: 'added removed',
                nsMode: 'default',
            },
            resources: tranlations,
        },() => {
            const currentLanguage = i18next.language
            moment.locale(currentLanguage)
        })
    );
    return i18nInstance;
}

export default i18nInit;