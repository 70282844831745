import React, { useState, useEffect } from "react";
import HeaderSmall from "../../components/Headers/HeaderSmall";
import { Container, Row, Card, CardHeader, Button, CardBody } from "reactstrap";
import QuestionList from './QuestionList';
import { useFirestore } from 'react-redux-firebase';

const Questions = ({ history }) => {

  const fireStore = useFirestore();
  const [questions, saveQuestions] = useState([]);

  useEffect(() => {
    if(questions.length === 0){    
      fireStore.collection('questions').get().then(result => {
        //console.log(result.docs[0].data());

        const questionList = [];

        for(const question of result.docs){
          questionList.push({...question.data(), id:question.id});
        }

        saveQuestions(questionList);

      })
    }
  },[fireStore, questions]);

  return (
    <>
      <HeaderSmall />
      <Container className="mt-2" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 mb-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="mb-0">Banco de Preguntas</h2>
                  </div>
                  <div className="col">
                    <Button color="success" size="sm" className="float-right" onClick={() => history.push('/dashboard/questions/create')}>
                      <i className="fa fa-plus"></i>
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                  <QuestionList 
                    questions ={questions}
                    isCheckRender = {false}
                    isAnswerSlice = {true}
                  />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Questions;
