export function buildCompleteSentence(answer){
    let sentence = answer.oracion;
    const options = answer.respuestas;
    let fullSentence = '';
    let i = 0;
    fullSentence = sentence.replace(/\?\?/g, function (match) {
        let replaceWith =  '<span class="answer">&nbsp;' + options[i].value + '&nbsp;</span>';
        // console.log(`i: ${i} asw: ${replaceWith} match: ${match}`);
        i++;
        return replaceWith;
    });

    return fullSentence;
}

export function buildColumsRelation(answer){
    let table = `<table> 
                
                    <tbody>`;

    answer.options.map(answ => (
        table = table.concat(
            `<tr> 
                <td> ${answ.question} </td>
            </tr>
            <tr> 
                <td><span class="answer">${answ.answer}</span></td>
            </tr>`
        )
    ))
    
    table = table.concat('</tbody> </table>');
    return table;
}