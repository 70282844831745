import React, {useState, useEffect} from 'react'
import {
    Button,
    Modal
  } from "reactstrap";

const GenericModal = ({title, body, open, closeModal}) => {

    const[isOpen, setOpen] = useState(false);

    useEffect(() => {
        if(open !== undefined)
            setOpen(open);
    },[open]);

    const toggleModal = () => {
        setOpen(!isOpen);
    }

    return ( 
        <Modal
              className="modal-dialog-centered font-customized"
              isOpen={isOpen}
              toggle={() => toggleModal()}
              onClosed = {() => closeModal()}
            >
            <div className="modal-header modal-header-bg">
                <h6 className="modal-title" id="modal-title-default">
                    {title}
                </h6>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModal()}
                >
                <span aria-hidden={true}>×</span>
                </button>
                </div>
                <div className="modal-body">
                    {body}
                </div>
                <div className="modal-footer">
                <Button color="success" type="button">
                    Save changes
                </Button>
                <Button
                    className="ml-auto"
                    color="danger"
                    type="button"
                    onClick={() => toggleModal()}
                >
                    Close
                </Button>
            </div>
        </Modal>
     );
}
 
export default GenericModal;