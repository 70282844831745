import React, {Fragment} from 'react'
import CompleteSentence from './CompleteSentence';
import ComlumsRelation from './ColumnsRelation';
import MultipleAnswers from './MultipleAnswers';

import {Row} from "reactstrap";

const QuestionBody = ({description, type, answer, isAnswerSlice}) => {

    //Choose which kind of question will be rendered
    const renderQuestionTypes = (type) => {
        switch(type){
            case 1:
            case 2:
                return <MultipleAnswers answer = {answer} isAnswerSlice = {isAnswerSlice}/>
            
            case 4:
                return <CompleteSentence answer = {answer} isAnswerSlice = {isAnswerSlice}/>

            case 5:
                return <ComlumsRelation answer = {answer} isAnswerSlice = {isAnswerSlice}/>

            default:
                return null;
        }
    }

    return ( 
        <Fragment>
            <Row>
                <div className="col" dangerouslySetInnerHTML={{ __html: description}}/>
            </Row>

            {renderQuestionTypes(type)}
        </Fragment>
     );
}
 
export default QuestionBody;