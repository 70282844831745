import SimpleReactValidator from 'simple-react-validator';
import es from '../assets/locale/es.js'

export function initializeValidator (forceUpdate, customValidator={}, customMessage={}){
    // const [, forceUpdate] = useState();
  
    SimpleReactValidator.addLocale('es', es);
    return new SimpleReactValidator({
      messages: customMessage, 
      validators: customValidator,
      locale: 'es',
      autoForceUpdate: { forceUpdate: () => forceUpdate(1) }
    });

    // return [validator.current, forceUpdate];
}