import React from "react";

// reactstrap components
import { Container } from "reactstrap";

class HeaderSmall extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-5 pt-4 pt-md-4">
          <Container fluid>
            <div className="header-body" />
          </Container>
        </div>
      </>
    );
  }
}

export default HeaderSmall;
