import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, 
  InputGroupText, InputGroup, Col, Alert } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import googleImg from './../assets/img/icons/common/google.svg';
import Loader from './../components/Loader';

const Login = ({ history }) => {
  const[ userEmail, setUserEmail ] = useState('');
  const[ userpsw, setUserPsw ] = useState('');
  const[ error, setError ] = useState(false);
  const auth = useSelector(state => state.firebase.auth);
  const firebase = useFirebase();

  const loginWithGoogle = () => {
    return firebase.login({ provider: 'google', type: 'popup' })
  }

  const loginCredentials = (e) => {
    e.preventDefault();
      setError(false);
      firebase.login({
        email: userEmail,
        password: userpsw
      }).then(() => {
        history.push('/dashboard');
      })
      .catch(() => {
        setError(true);
        setUserPsw('');
      })
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          {
             !isLoaded(auth)
             ? <Loader />
             : isEmpty(auth) ?
             <>
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                onClick={loginWithGoogle}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="Sing in with Google"
                    src={googleImg}
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={e => loginCredentials(e) }>
              { error &&
                <Alert className="alert-danger">
                  <strong>Ups!</strong> You'r credential are invalid, try again.
                </Alert>
              }
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={e => setUserEmail(e.target.value)}
                    value={userEmail}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={e => setUserPsw(e.target.value)}
                    value={userpsw}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Link
                  className="text-light"
                  to="/auth/forgotPassword"
                >
                  <small>Forgot password?</small>
                </Link>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody> </> : null
          }
        </Card>
      </Col>
    </>
  );
};

export default withRouter(Login);
