import React from 'react';
import { useSelector } from 'react-redux';
import Header from './../components/Headers/Header'
import { Row, Container, Card, CardHeader } from 'reactstrap';

const Main = () => {
    const userData = useSelector(state => state.auth.data);
    return ( 
        <>
            <Header userData={userData} />
            <Container className="mt--4" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Card tables</h3>
                            </CardHeader>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
     );
}
 
export default Main;