import Register from "./pages/Register";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Recovery from "./pages/Recovery";
import Assessment from "./pages/assessment/Assessment";
import CreateAssessment from "./pages/assessment/CreateAssessment";
import Questions from './pages/questions/Questions';
import CreateQuestion from "./pages/questions/CreateQuestion";

const routes = [
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/access",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/access",
  },
  {
    path: "/forgotPassword",
    name: "Forgot Password",
    icon: "ni ni-key-25 text-info",
    component: Recovery,
    layout: "/access",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-chart-pie-35 text-red",
    component: Main,
    layout: "/dashboard",
    sidebar: true,
  },
  {
    path: "/questions/create",
    name: "Create Question",
    icon: "ni ni-collection text-orange",
    component: CreateQuestion,
    layout: "/dashboard",
    sidebar: false,
  },
  {
    path: "/questions",
    name: "Questions",
    icon: "ni ni-ungroup text-blue",
    component: Questions,
    layout: "/dashboard",
    sidebar: true,
  },
  {
    path: "/evaluations",
    name: "Assessment",
    icon: "ni ni-collection text-orange",
    component: Assessment,
    layout: "/dashboard",
    sidebar: true,
  },
  {
    path: "/evaluations/create",
    name: "Create Assessment",
    icon: "ni ni-collection text-orange",
    component: CreateAssessment,
    layout: "/dashboard",
    sidebar: false,
  },
];


export default routes;