import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Progress, Button } from "reactstrap";
import styles from "./styles";

const Stepper = ({ arraySteps, activeStep }) => {
  return (
    <Row>
      {arraySteps && arraySteps.map((st, idx) => (
        <Col key={st.value}>
          <Row className="text-center d-flex align-items-center">
            <Col xs={6}>
              <Progress color="info" value={st.value <= activeStep ? "100" : "0"} />
            </Col>
            <Col xs="auto">
              <Button
                color="info"
                outline={st.value > activeStep}
                style={styles.buttonStep}
                onClick={(e) => e.preventDefault() }
              >
                {st.icon && st.icon !== "" ? (
                  <i className={st.icon}></i>
                ) : null }
                {` ${st.name}`}
              </Button>
            </Col>            
          </Row>
        </Col>
      ))}
    </Row>
  );
};

Stepper.propTypes = {
  arraySteps: PropTypes.array.isRequired,
};

export default Stepper;
