import React from 'react'
import { Row } from "reactstrap";

import {buildCompleteSentence} from '../../../utils/AnswersHelper';

const CompleteSentence = ({answer}) => {
    return ( 
        <Row>
            <div className="col mt-3">
                <div dangerouslySetInnerHTML={{ __html: buildCompleteSentence(answer)}}/>
            </div>
        </Row>
     );
}
 
export default CompleteSentence;