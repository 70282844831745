import React,{ useState, useEffect } from "react";
import { v4 as uuid } from 'uuid';
import { Row, Col, Table, Button } from "reactstrap";
import AnswerRow from "./AnswerRow";

const Columns = ({ callback }) => {
  const [options, setOptions] = useState([])
  const addNew = (e) => {
      e.preventDefault();
      let validAdd = true;

      for(const op of options){
        if(op.question === "" && op.answer === ""){
          validAdd = false;
          break;
        }
      }
      if(options.length === 0 || validAdd){
        setOptions([...options,{ isNew: true, question: '', answer: '', id : uuid() }]);
      }
  }

  useEffect(() => {
    callback({ data: { options }});
  },[options, callback])

  useEffect(() => {
      return () => {
        setOptions([]);
      }
  },[])

  const saveNewOption = (q, a, id) => {
    if(q !== "" && a !== ""){
      setOptions([...options.map(op => {
          if (op.id === id){
            op.question = q;
            op.answer = a;
            op.isNew = false;
          }
          return op;
      })])
    }
  }

  const deleteOption = (id) => {
    setOptions([...options.filter(op => op.id !== id)])
  }
  return (
    <>
      <Row className="mb-2">
          <Col sm="6" md="2" lg="2">
              <Button type="button" color="primary" className="btn-sm" onClick={e => addNew(e)}>
                  <i className="fa fa-plus"></i> Agregar
              </Button>
          </Col>
      </Row>
      <Row>
        <Col lg="12" md="12">
          <Table striped hover>
            <thead>
              <tr className="text-center">
                <th>Pregunta</th>
                <th>Respuesta</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody>
                { options.map(op => (
                    <AnswerRow {...op} key={op.id} saveAction={saveNewOption} deleteAction={deleteOption} />
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default Columns;
