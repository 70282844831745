import React, {useEffect, useState} from 'react'
import { useFirestore } from 'react-redux-firebase';
import shortId from 'shortid'

import {
    Badge
  } from "reactstrap";

const Categories = ({categories, isAnswerSlice}) => {

    const fireStore = useFirestore();

    const[categoriesToShow, setCategories] = useState([]);

    

    let i = 0;
    
    useEffect(() => {
    //GET THE NAME OF THE CATEGORIES BY THEIR ID
    const addCategory = (cat) => {
        fireStore.collection('cat_questions').where('value', '==', cat).get()
        .then(result => {
            if(result.docs.length > 0){
                setCategories(state => {
                    return [
                        ...state,
                        result.docs[0].data().label
                    ]
                })
            }
        }).catch(
            err => console.log(err)
            );
    }
    
    const buildCategories = (cat) => {
        if(isAnswerSlice === true){
            if (i<2){
                i++;
                addCategory(cat);
            }
        }else
            addCategory(cat);
    }

    //BY EACH ID CATEGORY...
        if(categories !== undefined){
            categories.forEach( cat => {
                buildCategories(cat);
            });
        }
    }, [fireStore, i, categories, isAnswerSlice]);
            
    return ( 
        <p className="mb-0 mt-2">
            {categoriesToShow.map(cate => (
                <Badge color="info" pill
                    key = {shortId.generate()}
                >
                    {cate}
                </Badge>

            ))}
            
        </p>
     );
}
 
export default Categories;